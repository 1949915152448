function MainTitle(){
    return (
        <div>
            <div className="bg-[#660199] m-5 max-w-sm	 rounded overflow-hidden shadow-lg">
                <img src="../ScratchAndWinRes/home.gif"/>
            </div>
            <div className="text-center">
                <h1 className="font-bold">Limited Period Scratch And Win Offer</h1>
                <h1 className="text-lg font-semibold">Peral Fuels</h1>
                <h1 className="text-lg font-semibold">Hospital Junction, Ezumatoor</h1>
            </div>
        </div>
    );
}

export default MainTitle;