import { useParams } from "react-router-dom";
import RedeemInfo from "../components/RedeemInfo";
import { useState, useEffect } from "react";
import OfferRules from "../components/OfferRules";
import CircularProgress from "@mui/material/CircularProgress";
import ConfettiExplosion from "react-confetti-explosion";

const cardTypes = [
  {
    name: "Thousand",
    div: (
      <div className="text-center m-1">
        <img src="../ScratchAndWinRes/thousand.png" />
        <p>Thousand Rupees</p>
      </div>
    ),
  },
  {
    name: "Hundred",
    div: (
      <div className="text-center m-1">
        <img src="../ScratchAndWinRes/hundred.png" />
        <p>Hundred Rupees</p>
      </div>
    ),
  },
  {
    name: "Fifty",
    div: (
      <div className="text-center m-1">
        <img src="../ScratchAndWinRes/fifty.png" />
        <p>Fifty Rupees</p>
      </div>
    ),
  },
  {
    name: "TwentyFive",
    div: (
      <div className="text-center m-1">
        <img src="../ScratchAndWinRes/twentyfive.png" />
        <p>Twenty Five Rupees</p>
      </div>
    ),
  },
  {
    name: "CarShampoo",
    div: (
      <div className="text-center m-1">
        <img src="../ScratchAndWinRes/carshampoo.png" />
        <p>Car Shampoo</p>
      </div>
    ),
  },
  {
    name: "BetterLuckNextTime",
    div: (
      <div className="text-center m-1">
        <img src="../ScratchAndWinRes/betterLuckNextTime.png" />
        <p>Better Luck Next Time</p>
      </div>
    ),
  },
];

function CardPage() {
  const [isExploding, setIsExploding] = useState(false);

  let { cardid } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const [cardInfo, setCardInfo] = useState(null);

  const [redeemed, setRedeeemed] = useState(false);

  useEffect(() => {
    fetch(
      "https://xiwg3vy4vd.execute-api.ap-south-1.amazonaws.com/Prod/api/Cards/" +
        cardid
    )
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result.message);
          const card = cardTypes.find((x) => x.name === result.data.cardType);
          setCardInfo(card ? card.div : null);
          setRedeeemed(result.data.isRedeemed);
          setIsLoading(false);
          setIsExploding(true);
        },
        (error) => {
          setIsLoading(false);
        }
      );
  }, []);

  return (
    <div>
      <div className="grid justify-items-center	">
        <div>
          {isExploding && (
            <ConfettiExplosion
              force={1}
              duration={5000}
              particleCount={250}
              width={1600}
            />
          )}
        </div>
      </div>
      <RedeemInfo />
      <div className="bg-[#660199] m-5 max-w-sm rounded overflow-hidden shadow-lg text-center">
        {isLoading ? (
          <CircularProgress color="primary" />
        ) : cardInfo ? (
          cardInfo
        ) : (
          <div>Card type not found</div>
        )}
      </div>
      {
        redeemed &&
        <div className="text-center text-red-700 bg-[#fff] m-5 max-w-sm rounded overflow-hidden shadow-lg">
          This prize has been redeemed!
        </div>
      }
      <OfferRules />
    </div>
  );
}

export default CardPage;
