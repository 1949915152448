function RedeemInfo() {
  return (
    <div className="text-center m-5 max-w-sm rounded overflow-hidden shadow-lg bg-[#32004c]  p-5">
      <p>
        Visit Pearl Fuels, Hospital Jnc. Ezuhmatoor to redeem you price and to recieve more scratch cards
        for fueling.
      </p>
    </div>
  );
}

export default RedeemInfo;
