import HomePage from "../pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CardPage from "../pages/CardPage";

function PublicRoutes(){
    return (
        <Routes>
            <Route exact path='/' element={<HomePage/>} />
            <Route exact path='/c/:cardid' element={<CardPage/>} />
        </Routes>
    );
}

export default PublicRoutes;