import MainTitle from "../components/MainTitle";
import OfferRules from "../components/OfferRules";

function HomePage(){
    return (
        <div>
            <MainTitle/>
            <div className="text-center m-5 max-w-sm rounded overflow-hidden shadow-lg bg-[#32004c]  p-5">
                <p>Visit Pearl Fuels to fuel your vehicle and recieve a scratch card with a chance to win various prices.</p>
            </div>
            <OfferRules/>
        </div>
    );
}

export default HomePage;